body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

header {
  background-color: #333;
  color: white;
  padding: 1em;
  text-align: center;
}

header nav a {
  color: white;
  margin: 0 1em;
  text-decoration: none;
}

section {
  padding: 2em;
  text-align: center;
}

/* components/Header.css */

.header {
    background-color: #333;
    color: white;
    padding: 1rem;
    text-align: center;
  }
  
  .header nav a {
    color: white;
    margin: 0 1rem;
    text-decoration: none;
  }
  
  .header nav a:hover {
    text-decoration: underline;
  }
  